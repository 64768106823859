import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import A4 from "../Images/A4";

const PublicationListItem = ({content}) => {
  const imageHeight = 29.7 / 21 * 100 // A4 = 21x29.7

  return (
      <div className="publication-list-item">
        <div className="flex flex-wrap">
          <div className="relative w-full md:w-1/3">
            <div aria-hidden={true} className="image-placeholder" style={{paddingBottom: `${imageHeight}%`}}></div>
            <figure className="image absolute inset-0 overflow-hidden">
              {content.relationships.image ? (
                  <Img
                      fluid={content.relationships.image.localFile.childImageSharp.fluid}
                      alt={content.title}
                  />
              ) : (
                  <A4 alt={content.title} className="absolute inset-0"/>
              )}
            </figure>
          </div>
          <div className="w-full md:w-2/3 bg-primary-light p-8 text-white">
            <h3 className="title h3 mb-4 uppercase">{content.title}</h3>
            <p className="mb-4">
              <a
                  href={content.relationships.file.localFile.publicURL}
                  title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex uppercase text-primary font-semibold bg-white hover:bg-primary hover:text-white transition-all"
              >
                <span className="button-label py-2 px-4">Visualiser</span>
                <span
                    className="button-icon py-2 px-8 bg-primary text-white transition-all">
              <i className="fas fa-file"><span
                  className="hidden">Visualiser</span></i>
            </span>
              </a>
            </p>
            <p className="mb-0">
              <a
                  href={content.relationships.file.localFile.publicURL}
                  title={`${content.title} (${content.relationships.file.localFile.prettySize})`}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex uppercase text-primary font-semibold bg-white hover:bg-primary hover:text-white transition-all"
                  download
              >
                <span className="button-label py-2 px-4">Télécharger</span>
                <span
                    className="button-icon py-2 px-8 bg-primary text-white transition-all">
              <i className="fas fa-download"><span
                  className="hidden">Télécharger</span></i>
            </span>
              </a>
            </p>
          </div>
        </div>
      </div>
  )
}

PublicationListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default PublicationListItem
